<template>

	<div class="help-info-page">
		<van-row class="title">
			<van-col span="24" v-html="name"></van-col>
		</van-row>
		<van-row class="desc">
			<van-col span="24" v-html="description"></van-col>
		</van-row>
		
	</div>

</template>

<script>
	export default {
		name: 'help_info',
		data() {
			return {
				formItem: {
					id: "",
				},
				
				name: "",
				description: "",
			}
		},

		mounted() {

			this.formItem.id = this.$route.query.id
			
			this.loadData()
						
		},
		methods: {
			loadData() {
				this.$toast.loading({
					message: '加载中...',
					forbidClick: true,
				})
				
				this.$axios({
					method: 'post',
					url: 'help/info', 
					data: this.formItem
				}).then ((res) => {
					
					this.$toast.clear()
					
					if (res.success) {
						this.name = res.data.name
						this.description = res.data.description
					} else {
						this.$dialog.alert({
							message: res.error_text
						})
					}
				})
			},
		}
	}
</script>